.goal-card-container {
  position: relative;
  margin-bottom: 10px; /* Space between goal cards */
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #ffffff;
  width: 100%;
}

/* Goal card header: title and expand button */
.goal-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ccc; /* Separate header from content */
  width: 100%;
}

.clarification-section {
  margin-top: 20px;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.clarification-text {
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
  line-height: 1.5;
}

.clarification-textbox {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  resize: none;
  box-sizing: border-box;
}

.clarification-textbox:focus {
  border-color: #0205d3;
  box-shadow: 0 0 5px rgba(2, 5, 211, 0.3);
}

.goal-card-header:hover, .goal-card-header:focus {
  border: 1px solid #0205D3;
}


.expand-button, .delete-button {
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  text-decoration: none;
}

.expand-button {
  color: #000;
}

.delete-button {
  position: absolute;
  top: 8px;
  right: 8px;
  color: grey;
  transition: color 0.3s ease;
}

.delete-button:hover {
  color: red;
}

.goal-title-header, .goal-title {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.goal-card {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 15px;
  min-width: 450px%;
  max-width: 100%;
}

/* Description and date in goal details */
.goal-description-section {
  margin-top: 10px;
}

.goal-description-text, .goal-date-text {
  font-size: 12px; /* Smaller font size for both */
  color: #555;
  text-align: left;
  margin-bottom: 5px;
}

.goal-date-text {
  color: #888;
  margin-bottom: 10px;
}

/* Save button for goals */
.goal-save {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: #ffffff;
  background-color: #0000FF;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.goal-save:hover {
  background-color: #0000cc;
}

.goal-save:active {
  background-color: #000099;
}

/* Error message */
.error-message {
  color: red;
  margin-top: 10px;
}


/* Common width for both the slider and the input */
.shared-width {
  max-width: 600px; /* Set a common max-width */
  width: 100%; /* Full width */
  margin: 0 auto; /* Center the content */
  box-sizing: border-box; /* Ensure padding/border don't affect width */
}
/* Update input section */
.update-input-section {
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding: 10px 0;
  max-width: 600px;
  width: 100%;
  margin-bottom: 30px; /* Consistent margin below input section */
  box-sizing: border-box;
}

.update-input {
  flex-grow: 1;
  border: none;
  outline: none;
  font-size: 16px;
  padding: 5px;
  background-color: transparent;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #f0f0f0; /* Light grey background when focused */

}

.update-input::placeholder {
  color: #999;
}

.update-textarea-expandable {
  flex-grow: 1;
  border: none;
  background-color: #999;
  padding: 10px 0;
  font-size: 1rem;
  font-family: "Work Sans", sans-serif;
  background-color: #f0f0f0;
  resize: none; /* Prevent manual resizing */
  overflow: hidden; /* Hide the scrollbar */
  min-height: 60px; /* Initial height (single line appearance) */
  outline: none; /* Remove default outline */
  border-radius: 8px;
  transition: height 0.2s ease; /* Smooth transition */
  line-height: 1.5; /* Increase line height for readability */
  padding: 20px;
}


.update-textarea-expandable::placeholder {
  color: #999; /* Placeholder color */
}

/* Update the background color when the user is typing (focused state) */
.update-input:focus, .update-input:hover,
.update-textarea-expandable:focus {

  border: 1px solid #0205d3;
}


.submit-update-button {
  background-color: transparent;
  border: none;
  font-size: 24px;
  color: #333;
  cursor: pointer;
  padding: 0 10px;
  box-sizing: border-box;
}


.submit-update-button:hover {
  color: #0205d3;
}

.submit-icon {
  font-weight: bold;
  font-size: 24px;
}


/* Optional: If using a textarea for updates */
.add-update-textarea {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
  font-family: "Work Sans", sans-serif;
  resize: none;
  box-sizing: border-box;
}

.add-update-textarea::placeholder {
  color: #999;
}

/* Add update button */
.add-update-button {
  background-color: #0205d3;
  color: white;
  padding: 8px 16px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-family: "Work Sans", sans-serif;
}

.add-update-button:hover {
  background-color: #0041cc;
}

/* Ensure container alignment */
.updates-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 10px 0; /* Add vertical spacing between elements */
  padding: 0 20px; /* Add padding to match the update card's padding */
  
}


.previous-update-header {
  font-size: 1.3rem; /* Slightly smaller font size for cleaner look */
  font-weight: bold;
  color: #333;
  margin: 0;
  text-align: left;
}

.summarize-progress-button {
  background-color: #0205d3;
  color: white;
  padding: 10px 14px; /* Smaller padding for a more compact button */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-family: "Work Sans", sans-serif;
  text-align: right;
  text-decoration:none;
}

.summarize-progress-button:hover {
  background-color: #0041cc;
}

.goal-updates {
  padding-top: 20px; /* Add top padding to create space between header and updates */
  margin-top: 20px;
}

/* Adjust the updates container and cards for consistency */
.goal-updates ul {
  list-style-type: none; /* Remove bullet points */
  padding: 0; /* Remove padding */
}

.goal-updates li {
  list-style-type: none;
  margin-bottom: 15px; /* Add more space between updates */
  padding: 15px 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for emphasis */
}

.goal-update-date {
  font-size: 12px;
  color: #999;
  margin-bottom: 5px;
  display: block; /* Ensure the date is on its own line */
}

.goal-update-message {
  font-size: 14px;
  line-height: 1.5; /* Improve readability */
  color: #333;
  margin: 0;
  text-align: left; /* Left-aligned */
  cursor: pointer;
}

.edit-update-textarea {
  width: 100%; /* Take up the full width of the parent container */
  box-sizing: border-box; /* Include padding and border in the width calculation */
  min-height: 60px; /* Minimum height for a comfortable starting size */
  font-size: 14px; /* Adjust font size for readability */
  padding: 10px; /* Padding inside the textarea */
  border: 1px solid #ddd; /* Subtle border */
  border-radius: 8px; /* Rounded corners */
  resize: none; /* Disable manual resizing */
  outline: none; /* Remove default outline */
  overflow: hidden; /* Prevent scrollbars */
}

.edit-update-textarea:focus {
  border-color: #0205d3; /* Highlight border on focus */
  box-shadow: 0 0 5px rgba(2, 5, 211, 0.3); /* Subtle focus glow */
}



.edit-actions {
  display: flex;
  gap: 8px;
  margin-top: 10px; /* Space above the buttons */
  justify-content: flex-end; /* Align buttons to the right */
}

.goal-update-message:hover {
  background-color: #f0f0f0;
}

.no-updates-placeholder {
  text-align: center;
  color: #999; /* Light grey like the timestamp */
  font-size: 14px; /* Same size as update message */
  margin-top: 20px; /* Add spacing to center it properly */
}

.progress-slider-section {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.completion-label {
  font-size: 14px;
  color: #333;
  margin-bottom: 8px;
  text-align: left;
}

/* Progress slider section */
.progress-slider-section {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 600px; /* Match the width to the update input */
  width: 100%; /* Full width */
  box-sizing: border-box;
}

.completion-label {
  font-size: 14px;
  color: #333;
  margin-bottom: 8px;
  text-align: left;
}

.progress-slider {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  height: 8px;
  background: #ddd; /* Default unfilled background */
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  position: relative;
}

.progress-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  background: #ddd; /* Unfilled portion */
  border-radius: 5px;
}

.progress-slider::-moz-range-track {
  width: 100%;
  height: 8px;
  background: #ddd; /* Unfilled portion */
  border-radius: 5px;
}

.progress-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #0205d3;
  cursor: pointer;
  margin-top: -4px; /* Align thumb with slider track */
}

.progress-slider::-moz-range-thumb {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #0205d3;
  cursor: pointer;
}

/* Filled part up to the current completion */
.progress-slider::-webkit-slider-runnable-track {
  background: linear-gradient(
    to right, 
    #0205d3 0%, 
    #0205d3 calc(var(--completion, 0) * 1%), 
    #ddd calc(var(--completion, 0) * 1%), 
    #ddd 100%
  );
}

.progress-slider::-moz-range-track {
  background: linear-gradient(
    to right, 
    #0205d3 0%, 
    #0205d3 calc(var(--completion, 0) * 1%), 
    #ddd calc(var(--completion, 0) * 1%), 
    #ddd 100%
  );
}

.section-separator {
  height: 2px;
  background-color: #ddd; /* Light grey line */
  margin: 20px 0; /* Spacing between sections */
  width: 100%;
  max-width: 600px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.page-number-button {
  background-color: #ddd;
  border: none;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
}

.page-number-button.active {
  background-color: #0205d3;
  color: #fff;
}

.page-number-button:hover {
  background-color: #0041cc;
  color: white;
}

.ai-suggestion-box {
  background-color: #f0f8ff; /* Light blue background for AI suggestions */
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Slight shadow for depth */
}

.ai-suggestion-header {
  font-weight: bold;
  color: #0205d3;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.ai-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  vertical-align: middle;
}

.ai-suggestion-body p{
  text-align: left !important; /* Left-aligns the text within the AI suggestion body */
  margin-top: 10px; /* Optional spacing between header and body */
  line-height: 1.5; /* Increase line spacing for readability */
}

.ai-suggestion-button-container {
  text-align: right; /* Aligns the button to the right */
  margin-top: 10px; /* Adds space between the body and button */
}

.confirm-button {
  background-color: #0205d3;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Work Sans', sans-serif;
  text-align: center;
  text-decoration: none;
  margin-top: 10px; /* Space between message and button */
}

.confirm-button:hover {
  background-color: #0041cc; /* Darker shade on hover */
}

.confirm-button:focus {
  outline: none;
}

.confirm-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}


/* Description and due date section */
.goal-card-details {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.goal-due-date {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
  font-weight: 500;
}

.card-icon {
  width: 20px;
  height: 20px;
  margin-right: 6px;
  vertical-align: middle;
}


.goal-title-input {
  font-size: 14px;
  font-weight: 400;
  background-color: transparent;
  border:none;
  color: #333;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
  outline: none;
  resize: none; /* Prevent manual resizing */
}

/* Align description section items */
.goal-description {
  display: flex;
  align-items: center;
}

.goal-description .card-icon {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  transition: color 0.3s ease;
}
/* Auto-expanding input (same style as the description input) */
.goal-description-input {
  flex-grow: 1;
  font-size: 14px;
  font-weight: 400;
  color: #333;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  resize: none; /* Prevent manual resizing */
  overflow: hidden; /* Hide the scrollbar */
  min-height: 20px; /* Initial height (single line appearance) */
}

.goal-description-input:focus, .goal-title-input:focus {
  border-color: #0205d3; /* Purple focus color */
  box-shadow: 0 0 5px #0041cc; /* Subtle glow on focus */
}

.goal-description-input.expanding {
  transition: height 0.2s ease; /* Smooth expansion */
}

.goal-description-editing {
  color: #0205D3; /* Icon color when in edit mode */
}

.card-icon {
  fill: currentColor; /* This makes the icon adapt to the text color */
  color: #007bff; /* Default color */
  transition: color 0.3s; /* Optional: Add smooth color transition */
}

.card-icon:hover {
  color: #ff5733; /* Hover color */
}
.see-more, .see-less {
  color: #007bff; /* Link color */
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
}

.see-more:hover, .see-less:hover {
  color: #0056b3; /* Darker hover effect */
}

/* Archive button section */
.archive-section {
  display: flex;
  justify-content: flex; /* Align the button to the right */
  margin-top: 10px; /* Add some space above the button */
  padding-right: 20px; /* Align with the other content */
}
.archive-button {
  background-color: #f5f5f5; /* Light background color */
  color: #333; /* Default text and icon color */
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 8px 16px; /* Padding for the button */
  font-size: 14px;
  cursor: pointer;
  display: flex; /* Flex layout for aligning text and icon */
  align-items: center;
  transition: background-color 0.3s ease, color 0.3s ease;
  text-decoration: none;
}

.archive-button:hover {
  background-color: #ffcccc; /* Light red background on hover */
  color: #ff0000; /* Text and icon turn red on hover */
  border-color: #ff0000; /* Border turns red on hover */
}


