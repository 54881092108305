/* General container styling */
.new-project-container {
    padding: 20px;
    font-family: "Work Sans", sans-serif;
  }
  
  /* Header styling */
  .new-project-header {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
  }
  
  /* Input styling */
  .project-input-label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 300;
    color: #999;
  }
  
  .project-input,
  .project-textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
    outline: none;
    transition: border-color 0.2s;
  }
  
  .project-input:focus,
  .project-textarea:focus {
    border-color: #007bff;
  }
  
  .project-textarea {
    height: 100px;
    resize: vertical;
    overflow: auto;
  }
  
  /* Submit button styling */
  .new-project-submit-button {
    background-color: #007bff;
    color: #fff;
    padding: 12px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%; /* Full width for a clean layout */
  }
  
  .new-project-submit-button:hover {
    background-color: #0056b3;
  }
  
  .new-project-submit-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

  .new-project-button {
    background-color: #f9f9f9; /* Light grey background */
    color: #333; /* Dark grey text */
    font-size: 14px;
    font-weight: bold;
    padding: 8px 16px;
    border: 1px solid #ccc; /* Darker grey border */
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .new-project-button:hover {
    background-color: #007bff; /* Blue background on hover */
    color: white; /* White text on hover */
    border-color: #007bff; /* Blue border on hover */
  }
  
  .new-project-button:disabled {
    background-color: #e6e6e6; /* Light grey background for disabled state */
    color: #999; /* Grey text for disabled state */
    border-color: #ccc; /* Grey border for disabled state */
    cursor: not-allowed;
  }
  