/* Home3.css */

/* General Page Styling */
.home-page {
  display: flex; /* Enables Flexbox layout */
  justify-content: center; /* Centers content horizontally */
  align-items: flex-start; /* Aligns content to the top */
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: #f7f9fc;
  min-height: 100vh; /* Ensures the container fills the viewport height */
}

.page-content {
  margin: auto;
}

.container {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
}

/* Greeting Section */
.greeting-section {
  justify-content: space-between;
  align-items: left;
  border-radius: 12px;
  padding: 20px;
}

.greeting-section h1 {
  font-size: 24px;
  font-weight: 200;
  color: #333;
  margin: 0;
}

.greeting-section button {
  background-color: #0041cc;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.greeting-section button:hover {
  background-color: #002f99;
}
