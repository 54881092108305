.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.navbar {
  width: 100%;
  height: 60px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff; /* Ensure a plain background */
  box-shadow: none; /* Explicitly remove any box-shadow */
  border-bottom: none; /* Ensure there's no bottom border */
}

.navbar * {
  box-shadow: none !important;
}

.logo {
  height: 20px;
  width: auto;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  padding: 100px 20px; /* Adjusted padding for better spacing */
}

.left-content {
  width: 55%; /* Increased width */
}

.right-content {
  width: 45%; /* Reduced width */
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-content h1 {
  font-size: 2.8rem;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 15px;
}

.left-content p {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 30px;
  text-align: left;
}

.btn-primary {
  background-color: #0041cc;
  color: #fff;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 1rem;
  transition: background 0.2s;
  text-decoration: none;
}

.btn-primary:hover {
  background-color: #002f99;
}

.right-content img {
  width: 70%; /* Reduced width */
  max-width: 500px; /* Adjusted max width */
  align-items: center;
}

.features {
  width: 100%;
  max-width: 1200px;
  padding: 40px 20px;
  margin: 0 auto;
  text-align: center;
  text-align: left;
  padding-top: 100px;
}

.features h2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #1a1a1a;
  margin-bottom: 10px;
  text-align: left;
}

.features-description {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 40px;
  text-align: left;
}

.feature-items {
  display: flex;
  gap: 2%; /* Use percentage for the gap to span 100% */
  justify-content: space-between; /* Ensure spacing around items */
  flex-wrap: wrap;
}

.feature-item {
  flex: 1 1 30%; /* Allows three items per row */
  max-width: 32%; /* Adjusted width to account for gap */
  min-height: 350px;
  background-color: #f9f9f9;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  
  display: flex;
  flex-direction: column;
  align-items: center; /* Horizontal center alignment */
  justify-content: center; /* Vertical center alignment */
  
  text-align: center;
  box-sizing: border-box;
}

.feature-icon {
  font-size: 2.5rem;
  color: #0041cc;
  margin-bottom: 20px;
  max-width: 20%;
}

.feature-item h3 {
  font-size: 1.25rem;
  font-weight: bold;
  color: #1a1a1a;
  margin-bottom: 10px;
}

.feature-item p {
  font-size: 1rem;
  color: #666;
  line-height: 1.6;
}

/* Impact Banner Section Styling */
.impact-banner {
  position: relative;
  width: 100vw; /* Full viewport width */
  height: auto;
  min-height: 400px; /* Minimum height as a fallback for smaller screens */
  aspect-ratio: 2 / 1; /* Adjust aspect ratio based on your image's dimensions */
  margin-left: calc(-50vw + 50%); /* Center-align for full-width */
  color: #333;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.impact-banner-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the entire section */
  object-position: center; /* Centers the image */
  z-index: 0;
  pointer-events: none;
}

/* Wrapper for all content on top of the banner */
.impact-banner-content {
  position: relative;
  z-index: 1; /* Ensures content appears above the background image */
  color: #fff; /* Make text white for better contrast on dark background */
  max-width: 90%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* Styles for heading and paragraph inside the impact banner */
.impact-banner h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #ffffff; /* Ensure text is readable over the background */
}

.impact-banner p {
  font-size: 1.25rem;
  max-width: 800px;
  line-height: 1.6;
  color: #f0f0f0; /* Light color for readability */
}

.how-it-works {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: flex-start;
  margin-top: 20px;
}

.how-step {
  opacity: 0;
  transform: translateY(20px); /* Start slightly below */
  transition: opacity 0.6s ease, transform 0.6s ease;
  width: 100%;
  max-width: 90%; /* Longer shape */
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 8px;
  color: #333;
  text-align: center;
}

.how-step h3 {
  font-size: 1.25rem;
  font-weight: bold;
  color: #1a1a1a;
  margin-bottom: 10px;
}

.how-step p {
  font-size: 1rem; /* Increase font size */
  color: #444; /* Darker color for better readability */
  line-height: 1.6;
}

.how-step.slide-in {
  opacity: 1;
  transform: translateY(0); /* Slide in to original position */
}

.how-step.slide-out {
  opacity: 0;
  transform: translateY(20px); /* Slide back down */
}

.how-step:nth-child(1).slide-in {
  transition-delay: 0.2s;
}
.how-step:nth-child(2).slide-in {
  transition-delay: 0.4s;
}
.how-step:nth-child(3).slide-in {
  transition-delay: 0.6s;
}

.step-icon {
  width: 40px; /* Define a consistent width */
  height: 40px; /* Define a consistent height */
  max-width: 100%; /* Prevents overflow */
  max-height: 100%; /* Prevents overflow */
  object-fit: contain; /* Ensures the icon scales proportionally */
  margin-bottom: 10px; /* Adds spacing between the icon and text */
}

/* Fullscreen-style Try It Out Section */
.try-it-fullscreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 80%;
  max-width: 80%;
  padding: 60px 20px;
  text-align: center;
  overflow: visible; /* Ensure no overflow issues */
}


.try-it-fullscreen h2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #1a1a1a;
  margin-bottom: 20px;
}

.typed-text {
  font-size: 1.25rem;
  color: #1a1a1a;
  min-width: 80%;
  max-width: 80%;
  margin: 0 auto 20px; /* Center align with some spacing at the bottom */
  line-height: 1.6;
  white-space: pre-wrap;
  padding: 0; /* Reset padding */
  margin: 0; /* Reset margin */
  overflow: visible; /* Ensure text overflow is visible */
}


.chatgpt-style-input {
  max-width: 600px;
  width: 80%;
  padding: 15px;
  font-size: 1.1rem;
  color: #333;
  border: none;
  background: #efefef;
  border-radius: 5px;
  outline: none;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  transition: box-shadow 0.3s ease;
  resize: none;
}

/* ChatGPT-style input */
.chatgpt-style-input-date-input {
  width: 300px;
  max-width: 400px;
  padding: 15px;
  font-size: 1.1rem;
  color: #333;
  border: none;
  background: #efefef;
  border-radius: 5px;
  outline: none;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  transition: box-shadow 0.3s ease;
  resize: none;
}

/* Try-It-Out Section */
.try-it-out {
  background-color: #f9f9f9;
  padding: 60px 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 100%;
  margin: 40px auto;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: opacity 0.6s ease, transform 0.6s ease;
  opacity: 0;
  transform: translateY(20px);
}

/* Style the "Try It Out" heading for better visibility */
.try-it-out-heading {
  font-size: 2.5rem;
  font-weight: bold;
  color: #1a1a1a;
  text-align: center;
  margin-bottom: 30px; /* Add space below the heading */
  margin-top: 20px; /* Add space above the heading */
}

.try-it-out.visible {
  opacity: 1;
  transform: translateY(0);
}

.try-it-out-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

/* Fade-out animation for the heading */
.fade-out {
  opacity: 0;
  transition: opacity 1s ease-out; /* Adjust duration as needed */
}

.text-content {
  flex: 1;
  width: 50%;
  padding-right: 20px;
}

.text-content h2 {
  font-size: 2rem;
  font-weight: bold;
  color: #000b21;
  text-align: left;
  margin-bottom: 15px;
}

.text-content p {
  font-size: 1.2rem;
  text-align: left;
  color: #555;
  margin-bottom: 30px;
}

.image-content {
  flex: 1;
  display: flex;
  justify-content: center;
}

.image-content img {
  width: 80%; /* Adjust as needed */
  max-width: 400px;
}

/* Step Content Styles */
.step-content {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 20px; /* Add padding for consistency */
  transition: opacity 0.5s ease, transform 0.5s ease;
  background-color: #ffffff; /* Ensure a background color */
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05); /* Light shadow for box appearance */
}

.step-content h2 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.step-content p {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 20px;
}

.input-box {
  width: 100%;
  padding: 12px;
  margin: 10px 0 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1.1rem;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  resize: none;
}
.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.navbar {
  width: 100%;
  height: 60px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff; /* Ensure a plain background */
  box-shadow: none; /* Explicitly remove any box-shadow */
  border-bottom: none; /* Ensure there's no bottom border */
}

.navbar * {
  box-shadow: none !important;
}

.logo {
  height: 20px;
  width: auto;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  padding: 100px 20px; /* Adjusted padding for better spacing */
}

.left-content {
  width: 55%; /* Increased width */
}

.right-content {
  width: 45%; /* Reduced width */
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-content h1 {
  font-size: 2.8rem;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 15px;
}

.left-content p {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 30px;
  text-align: left;
}

.btn-primary {
  background-color: #0041cc;
  color: #fff;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 1rem;
  transition: background 0.2s;
  text-decoration: none;
}

.btn-primary:hover {
  background-color: #002f99;
}

.right-content img {
  width: 70%; /* Reduced width */
  max-width: 500px; /* Adjusted max width */
  align-items: center;
}

.features {
  width: 100%;
  max-width: 1200px;
  padding: 40px 20px;
  margin: 0 auto;
  text-align: center;
  text-align: left;
  padding-top: 100px;
}

.features h2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #1a1a1a;
  margin-bottom: 10px;
  text-align: left;
}

.features-description {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 40px;
  text-align: left;
}

.feature-items {
  display: flex;
  gap: 2%; /* Use percentage for the gap to span 100% */
  justify-content: space-between; /* Ensure spacing around items */
  flex-wrap: wrap;
}

.feature-item {
  flex: 1 1 30%; /* Allows three items per row */
  max-width: 32%; /* Adjusted width to account for gap */
  min-height: 350px;
  background-color: #f9f9f9;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  
  display: flex;
  flex-direction: column;
  align-items: center; /* Horizontal center alignment */
  justify-content: center; /* Vertical center alignment */
  
  text-align: center;
  box-sizing: border-box;
}

.feature-icon {
  font-size: 2.5rem;
  color: #0041cc;
  margin-bottom: 20px;
  max-width: 20%;
}

.feature-item h3 {
  font-size: 1.25rem;
  font-weight: bold;
  color: #1a1a1a;
  margin-bottom: 10px;
}

.feature-item p {
  font-size: 1rem;
  color: #666;
  line-height: 1.6;
}

/* Impact Banner Section Styling */
.impact-banner {
  position: relative;
  width: 100vw; /* Full viewport width */
  height: auto;
  min-height: 400px; /* Minimum height as a fallback for smaller screens */
  aspect-ratio: 2 / 1; /* Adjust aspect ratio based on your image's dimensions */
  margin-left: calc(-50vw + 50%); /* Center-align for full-width */
  color: #333;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.impact-banner-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the entire section */
  object-position: center; /* Centers the image */
  z-index: 0;
  pointer-events: none;
}

/* Wrapper for all content on top of the banner */
.impact-banner-content {
  position: relative;
  z-index: 1; /* Ensures content appears above the background image */
  color: #fff; /* Make text white for better contrast on dark background */
  max-width: 90%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* Styles for heading and paragraph inside the impact banner */
.impact-banner h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #ffffff; /* Ensure text is readable over the background */
}

.impact-banner p {
  font-size: 1.25rem;
  max-width: 800px;
  line-height: 1.6;
  color: #f0f0f0; /* Light color for readability */
}

.how-it-works {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: flex-start;
  margin-top: 20px;
}

.how-step {
  opacity: 0;
  transform: translateY(20px); /* Start slightly below */
  transition: opacity 0.6s ease, transform 0.6s ease;
  width: 100%;
  max-width: 90%; /* Longer shape */
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 8px;
  color: #333;
  text-align: center;
}

.how-step h3 {
  font-size: 1.25rem;
  font-weight: bold;
  color: #1a1a1a;
  margin-bottom: 10px;
}

.how-step p {
  font-size: 1rem; /* Increase font size */
  color: #444; /* Darker color for better readability */
  line-height: 1.6;
}

.how-step.slide-in {
  opacity: 1;
  transform: translateY(0); /* Slide in to original position */
}

.how-step.slide-out {
  opacity: 0;
  transform: translateY(20px); /* Slide back down */
}

.how-step:nth-child(1).slide-in {
  transition-delay: 0.2s;
}
.how-step:nth-child(2).slide-in {
  transition-delay: 0.4s;
}
.how-step:nth-child(3).slide-in {
  transition-delay: 0.6s;
}

.step-icon {
  width: 40px; /* Define a consistent width */
  height: 40px; /* Define a consistent height */
  max-width: 100%; /* Prevents overflow */
  max-height: 100%; /* Prevents overflow */
  object-fit: contain; /* Ensures the icon scales proportionally */
  margin-bottom: 10px; /* Adds spacing between the icon and text */
}

/* Fullscreen-style Try It Out Section */
.try-it-fullscreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 60px 20px;
  text-align: center;
  overflow: visible; /* Ensure no overflow issues */
}


.try-it-fullscreen h2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #1a1a1a;
  margin-bottom: 20px;
}

.typed-text {
  font-size: 1.25rem;
  color: #1a1a1a;
  min-width: 80%;
  max-width: 80%;
  margin: 0 auto 20px; /* Center align with some spacing at the bottom */
  line-height: 1.6;
  white-space: pre-wrap;
  padding: 0; /* Reset padding */
  margin: 0; /* Reset margin */
  overflow: visible; /* Ensure text overflow is visible */
}




/* Try-It-Out Section */
.try-it-out {
  background-color: #f9f9f9;
  padding: 60px 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 100%;
  margin: 40px auto;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: opacity 0.6s ease, transform 0.6s ease;
  opacity: 0;
  transform: translateY(20px);
}

/* Style the "Try It Out" heading for better visibility */
.try-it-out-heading {
  font-size: 2.5rem;
  font-weight: bold;
  color: #1a1a1a;
  text-align: center;
  margin-bottom: 30px; /* Add space below the heading */
  margin-top: 20px; /* Add space above the heading */
}

.try-it-out.visible {
  opacity: 1;
  transform: translateY(0);
}

.try-it-out-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

/* Fade-out animation for the heading */
.fade-out {
  opacity: 0;
  transition: opacity 1s ease-out; /* Adjust duration as needed */
}

.text-content {
  flex: 1;
  width: 50%;
  padding-right: 20px;
}

.text-content h2 {
  font-size: 2rem;
  font-weight: bold;
  color: #000b21;
  text-align: left;
  margin-bottom: 15px;
}

.text-content p {
  font-size: 1.2rem;
  text-align: left;
  color: #555;
  margin-bottom: 30px;
}

.image-content {
  flex: 1;
  display: flex;
  justify-content: center;
}

.image-content img {
  width: 80%; /* Adjust as needed */
  max-width: 400px;
}

/* Step Content Styles */
.step-content {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 20px; /* Add padding for consistency */
  transition: opacity 0.5s ease, transform 0.5s ease;
  background-color: #ffffff; /* Ensure a background color */
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05); /* Light shadow for box appearance */
}

.step-content h2 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.step-content p {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 20px;
}

.input-box {
  width: 100%;
  padding: 12px;
  margin: 10px 0 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1.1rem;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  resize: none;
}

/* Assistant message container */
.assistant-message-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  margin: 20px auto;
  text-align: center;
}

/* Styled text block for the assistant message */
.assistant-message-text {
  background-color: #f0f4ff; /* Light blue background */
  padding: 15px 20px;
  border-radius: 8px;
  color: #333;
  font-size: 1rem;
  width: 100%;
  text-align: left;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px; /* Space below message */
}

/* Assistant message input for editing */
.assistant-message-input {
  width: 100%;
  padding: 15px;
  resize: none;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

/* Button container */
.assistant-message-btns {
  display: flex;
  gap: 10px;
}

/* Edit button */
.edit-btn {
  background: transparent;
  color: #333;
  border: 1px solid #333;
  border-radius: 5px;
  padding: 8px 15px;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.3s, border-color 0.3s;
}

.edit-btn:hover {
  color: #0056b3;
  border-color: #0056b3;
}

/* Confirm "Looks good" button */
.confirm-btn {
  background-color: #0041cc;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-decoration: none;
}

.confirm-btn:hover {
  background-color: #003399;
}

/* Step Content Styling */
.step-content {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 20px; /* Add padding for consistency */
  transition: opacity 0.5s ease, transform 0.5s ease;
  background-color: #ffffff; /* Ensure a background color */
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05); /* Light shadow for box appearance */
}

/* Input Box Styling */
.input-box {
  width: 100%;
  padding: 12px;
  margin: 10px 0 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1.1rem;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

/* Button Styling */
.btn-next {
  background-color: #0041cc;
  color: white;
  padding: 12px 25px;
  margin-top: 20px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease;
  text-decoration: none;
}

.btn-next:hover {
  background-color: #002f99;
}

.assistant-message-block {
  background-color: #f0f4ff; /* Light blue background */
  padding: 15px;
  border-radius: 5px;
  color: #333;
  font-size: 1rem;
  margin-top: 20px;
}

/* Button container */
.assistant-message-btns {
  display: flex;
  gap: 10px;
}

/* Edit button */
.edit-btn {
  background: transparent;
  color: #333;
  border: 1px solid #333;
  border-radius: 5px;
  padding: 8px 15px;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.3s, border-color 0.3s;
}

.edit-btn:hover {
  color: #0056b3;
  border-color: #0056b3;
}

/* Hide 'Get Started' button on mobile */
@media (max-width: 768px) {
    .btn-get-started {
      display: none;
    }
    .impact-banner {
      display: none;
    }
    .impact-banner-content h2 {
      font-size: 2rem;
    }
    .left-content {
        width: 100%;
    }
    /* Adjust font sizes for mobile */
    .left-content h1 .features h2 {
      font-size: 2rem; /* Reduce font size */
    }

      /* Hide right content (side image) on mobile */
    .right-content {
        display: none;
    }
    .update-banner{
        height:200px;
    }
    .left-content p,
    .features-description,
    .feature-item h3,
    .feature-item p,
    .update-banner p {
      font-size: 1rem;
    }
  
    .feature-items {
        flex-direction: column;
        gap: 20px; /* Add space between stacked items */
      }
    
      .feature-item {
        max-width: 100%; /* Full width for each feature item */
        margin: 0 auto; /* Center each item */
    }

    .landing-footer {
      font-size: 0.8rem;
    }

    .try-it-fullscreen {
      padding: 40px 10px; /* Reduce padding */
      text-align: center;
      width: 100%; /* Full width on mobile */
      max-width: 100%; /* Remove max-width constraint */
    }
    
    .try-it-fullscreen h2 {
      font-size: 2.5rem; /* Smaller heading size */
      margin-bottom: 15px;
    }
    
    .chatgpt-style-input {
      width: 90%; /* Take more horizontal space */
      max-width: none; /* Remove constraints */
      font-size: 1rem; /* Slightly smaller font */
    }
    
    .chatgpt-style-input-date-input {
      width: 90%;
    }
    
    .typed-text {
      font-size: 1rem; /* Adjust text size */
      width: 90%;
      margin-bottom: 15px;
    }
  }

/* Navbar buttons */
.nav-buttons {
  margin-left: auto; /* Push buttons to the right */
  display: flex;
  gap: 10px;
}

.btn-signin, .btn-get-started {
  padding: 10px 20px;
  font-size: 0.9rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
}

.btn-signin {
  background-color: transparent;
  color: #0041cc;
  border: 1px solid #0041cc;
}

.btn-signin:hover {
  background-color: #0041cc;
  color: white;
}

.btn-get-started {
  background-color: #0041cc;
  color: white;
}

.btn-get-started:hover {
  background-color: #002f99;
}

.landing-footer{
  text-align: left;
  padding: 1rem;
  background-color: #f8f9fa; /* Or your desired color */
  font-size: 0.5rem !important; /* Smaller font size */
  color: #6c757d; /* Light gray color */
  width: 100%;
}

/* Hide 'Get Started' button on mobile */
@media (max-width: 768px) {
    .btn-get-started {
      display: none;
    }
    .impact-banner {
      display: none;
    }
    .impact-banner-content h2 {
      font-size: 2rem;
    }
    .left-content {
        width: 100%;
    }
    /* Adjust font sizes for mobile */
    .left-content h1 .features h2 {
      font-size: 2rem; /* Reduce font size */
    }

      /* Hide right content (side image) on mobile */
    .right-content {
        display: none;
    }
    .update-banner{
        height:200px;
    }
    .left-content p,
    .features-description,
    .feature-item h3,
    .feature-item p,
    .update-banner p {
      font-size: 1rem;
    }
  
    .feature-items {
        flex-direction: column;
        gap: 20px; /* Add space between stacked items */
      }
    
      .feature-item {
        max-width: 100%; /* Full width for each feature item */
        margin: 0 auto; /* Center each item */
    }

    .landing-footer {
      font-size: 0.8rem;
    }
  }

/* Navbar buttons */
.nav-buttons {
  margin-left: auto; /* Push buttons to the right */
  display: flex;
  gap: 10px;
}

.btn-signin, .btn-get-started {
  padding: 10px 20px;
  font-size: 0.9rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
}

.btn-signin {
  background-color: transparent;
  color: #0041cc;
  border: 1px solid #0041cc;
}

.btn-signin:hover {
  background-color: #0041cc;
  color: white;
}

.btn-get-started {
  background-color: #0041cc;
  color: white;
}

.btn-get-started:hover {
  background-color: #002f99;
}

.landing-footer{
  text-align: left;
  padding: 1rem;
  background-color: #f8f9fa; /* Or your desired color */
  font-size: 0.5rem !important; /* Smaller font size */
  color: #6c757d; /* Light gray color */
  width: 100%;
}