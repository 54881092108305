/* Main container holding sidebar and content */
.main-container {
    display: flex;
    height: 100vh; /* Ensure the container takes the full viewport height */
}

/* Sidebar Styles */
.sidebar {
    background-color: #f9f9f9;
    width: 200px;
    transition: transform 0.3s ease, width 0.3s ease;
    transform: translateX(0); /* Default position of the sidebar */
    flex-shrink: 0; /* Prevents sidebar from shrinking */
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    border-left: 4px solid #ececec; /* Adds a left border */
    display: flex;
    flex-direction: column; /* Stack sidebar content vertically */
}

/* Sidebar Footer */
.sidebar-footer {
    width: 100%;
    text-align: center;
    padding: 15px 20px;
    background-color: #f9f9f9;
    border-top: 1px solid #ececec; 
    margin-top: auto; /* Pushes footer to the bottom */
    box-sizing: border-box;
}

/* Sidebar Toggle Button */
.sidebar-toggle {
    position: fixed; /* Ensure the toggle stays in a fixed position */
    top: 10px; /* Adjust this value to control vertical positioning */
    left: 10px; /* Adjust this value to control horizontal positioning */
    z-index: 1100; /* High z-index to keep it on top of other elements */
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 8px;

}

.sidebar-toggle-icon {
    width: 30px;
    height: auto;
}

.sidebar-toggle:hover {
    background-color: #ececec; /* Changes background on hover */
}

/* Sidebar Header */
.sidebar-header {
    font-size: 1.5em;
    padding: 20px;
    font-weight: bold;
    text-align: right;
    width: 100%;
    color: #B8B8B8;
}

/* Sidebar Logo */
.sidebar-logo {
    width: 75px;
    height: auto;
    padding: 10px;
}

/* Sidebar Navigation */
.sidebar ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
    flex-grow: 1; /* Ensures the ul takes up available space */
}

.sidebar li {
    padding: 10px 15px;
    margin: 0 10px;
    border-radius: 8px;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 16px;
    text-align: left;
    transition: background-color 0.3s ease;
    color: black;
    text-decoration: none;
}

.sidebar li a {
    color: inherit;
    text-decoration: none;
    display: block;
    width: 100%;
    height: 100%;
    font-weight: normal;
    padding: 10px 0;
}

.sidebar li:hover {
    background-color: #ececec; /* Highlight on hover */
}

.sidebar li.active {
    background-color: #ececec;
    font-weight: bold; /* Highlight active item */
}

.sidebar li + li {
    margin-top: 10px; /* Adds space between list items */
}

/* Sidebar Profile */
.sidebar-profile {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 10px 15px;
    width: calc(100% - 20px); /* Full width minus padding */
    box-sizing: border-box;
    background-color: #ececec;
    border-radius: 8px;
    transition: background-color 0.3s ease;
    margin: 0 10px 10px 10px;
    cursor: pointer; /* Make the entire profile clickable */
}

.sidebar-profile-image {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.sidebar-profile-name {
    color: black;
    font-size: 16px;
    font-weight: normal;
}

.sidebar-profile:hover,
.sidebar-profile.active {
    background-color: #dcdcdc; /* Highlight on hover or when active */
    font-weight: bold;
}

/* Sidebar Footer Text */
.sidebar-footer-text {
    color: #B8B8B8;
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
}

/* Dropdown Menu */
.dropdown-menu {
    position: absolute;
    bottom: 60px; /* Position just above the footer text */
    left: 10px;
    width: calc(100% - 20px); /* Full width minus padding */
    background-color: #ffffff;
    border: 1px solid #ececec;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* Ensure the dropdown is above other content */
}

.dropdown-item {
    padding: 10px 15px;
    text-align: left;
    color: black;
    text-decoration: none;
    display: block;
    font-size: 16px;
    cursor: pointer;
}

.dropdown-item:hover {
    background-color: #f0f0f0; /* Highlight on hover */
    border-radius: 8px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .sidebar {
        width: 200px; /* Adjust width for smaller screens */
    }

    .sidebar.closed {
        transform: translateX(-200px); /* Adjust based on new width */
    }
}

.sidebar-profile-dropdown {
    position: absolute;
    top: 50px; /* Adjust based on your header height */
    left: 10px; /* Adjust based on the alignment you want */
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 10px;
    width: 150px; /* Adjust width as needed */
}
  
.sidebar-profile-dropdown ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
  
.sidebar-profile-dropdown li {
    padding: 10px;
    cursor: pointer;
}
  
.sidebar-profile-dropdown li:hover {
    background-color: #f0f0f0;
}
