.page {
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    background-color: #f7f9fc;
    min-height: 100vh;
}

.page-content{
    vertical-align: top;
    width: 100%;
    max-width: 800px; 
}

.breadcrumb {
    align-self: flex-start;
    font-size: 14px;
    color: #666;
}

.breadcrumb a {
    text-decoration: none;
    color: #007bff;
}

.project-header {
    max-width: 800px;
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
}

.project-header h1 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #666;
}

.project-header .description {
    font-size: 16px;
    color: #555;
    margin-bottom: 20px;
    text-align: left;
}

.progress-container {
    background-color: #e5e5e5; /* Gray for unfilled portion */
    border-radius: 8px;
    height: 12px; /* Set consistent height for progress bar */
    width: 100%;
    overflow: hidden;
    position: relative;
}

.progress-bar {
    background-color: #28a745; /* Green for progress */
    height: 100%;
    transition: width 0.3s ease;
    border-radius: 8px; /* Smooth edges for the filled portion */
}

.progress-slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 12px; /* Match the progress bar height */
    -webkit-appearance: none;
    appearance: none;
    background: transparent; /* Ensure the slider track is transparent */
    z-index: 2;
    cursor: pointer;
}

/* Customizing the slider thumb */
.progress-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 16px; /* Slightly larger than the progress bar */
    width: 16px; /* Circular thumb */
    border-radius: 50%;
    background-color: #28a745; /* Same green as the progress bar */
    cursor: pointer;
    margin-top: -2px; /* Align thumb vertically with the progress bar */
}

.progress-slider::-moz-range-thumb {
    height: 16px; /* Match webkit thumb size */
    width: 16px; /* Circular thumb */
    border-radius: 50%;
    background-color: #28a745; /* Same green as the progress bar */
    cursor: pointer;
    margin-top: -2px; /* Align thumb vertically with the progress bar */
}

.progress-slider::-webkit-slider-runnable-track {
    background: transparent; /* Ensure the track is transparent */
}

.progress-slider::-moz-range-track {
    background: transparent; /* Ensure the track is transparent */
}

.progress-text {
    font-size: 14px;
    color: #555;
    margin-top: 10px;
    text-align: right;
}


.content-box {
    background-color: #f3f3f3;
    border-radius: 12px;
    padding: 20px;
    max-width: 800px;
    width: 100%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.header {
    display: flex;
    flex-direction: row; /* Align items horizontally */
    justify-content: space-between; /* Tabs on the left, button on the right */
    align-items: center; /* Vertically center items */
    gap: 20px; /* Optional: space between tabs and the button */
    margin-bottom: 20px;
    width: 100%;
}

.tabs {
    display: flex;
    gap: 15px; /* Space between tab buttons */
    align-items: center;
}

.tab-button {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
    border: none;
    background: none;
    font-size: 16px;
    cursor: pointer;
    color: #555; /* Default color for text and icon */
    text-decoration: none;
    line-height: 1.5; /* Ensure consistent vertical alignment with the button */

}



.tab-button.hover .icon {
    color:#999
}

.tab-button.active {
    color: #007bff;
}

.tab-button .icon {
    fill: #555; /* Use parent's color */
}

.tab-button.active .icon {
    fill: #007bff; /* This ensures the icon inherits the color */
}

.tab-content {
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.update {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 10px;
}

.update .timestamp {
    font-size: 12px;
    color: #999;
    margin-top: 5px;
    display: block;
}
/* Shared header styles */
.shared-header-style {
    font-size: 12px; /* Match font size */
    font-weight: 100; /* Bold text */
    color: #999; /* Light gray color */
    text-transform: uppercase; /* Uppercase text */
    margin-bottom: 15px; /* Add spacing */
    margin-top: 10px; 
}

/* Details section adjustments */
.details h4 {
    margin-bottom: 3px;
    padding: 0;
}

.details .header-title {
    margin-top: 10px; /* Add spacing if needed */
}

.details .header-value {
    font-size: 14px;
    color: #333; /* Darker text for values */
}

.details p {
    text-align: left;
}

.action-button {
    flex-shrink: 0; /* Prevent shrinking */
}

.default-button {
    background-color: #f9f9f9; /* Light grey background */
    color: #333; /* Dark grey text */
    font-size: 14px;
    font-weight: bold;
    padding: 8px 16px;
    border: 1px solid #ccc; /* Darker grey border */
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .default-button:hover {
    background-color: #007bff; /* Blue background on hover */
    color: white; /* White text on hover */
    border-color: #007bff; /* Blue border on hover */
  }
  
  .default-button:disabled {
    background-color: #e6e6e6; /* Light grey background for disabled state */
    color: #999; /* Grey text for disabled state */
    border-color: #ccc; /* Grey border for disabled state */
    cursor: not-allowed;
  }

  .editable-textarea {
    width: 100%; /* Full width of the parent container */
    height: auto; /* Automatically adjust height */
    min-height: 40px; /* Set a minimum height for better usability */
    padding: 10px; /* Add some inner padding */
    font-size: 16px; /* Match font size with other inputs */
    font-family: "Inter", sans-serif; /* Ensure consistent font */
    border: 1px solid #ccc; /* Subtle border */
    border-radius: 5px; /* Rounded corners for a polished look */
    box-sizing: border-box; /* Include padding and border in width/height */
    resize: vertical; /* Allow vertical resizing but prevent horizontal resizing */
    transition: border-color 0.3s, box-shadow 0.3s; /* Smooth focus transitions */
}
