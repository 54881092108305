.page {
    display: flex;
    flex-direction: column;
    gap: 30px; /* Increased spacing between sections */
    padding: 40px; /* Add spacing around the container */
    background-color: #f7f9fc; /* Subtle background for readability */
    min-height: 100vh;
}

.page-content {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 30px; /* Padding inside the content container */
    border-radius: 12px; /* Rounded corners */
}

.breadcrumb {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
}

.breadcrumb a {
    text-decoration: none;
    color: #007bff;
    font-weight: bold;
}

.report-header {
    text-align: left;
    margin-bottom: 30px;
}

.report-header h1 {
    font-size: 28px; /* Consistent heading size */
    font-weight: 600;
    margin-bottom: 15px;
    color: #333;
}

.report-header .metadata {
    font-size: 16px;
    color: #555;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Space between metadata items */
}

.metadata p {
    margin: 0;
    font-size: 16px;
    color: #555;
}

.content-box {
    background-color: #fff;
    border-radius: 12px;
    padding: 25px; /* Increased padding for content */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    width: 100%;
    line-height: 1.8; /* Increased line height for better readability */
    font-size: 16px; /* Uniform font size */
}

.content-box h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
    color: #333;
}

.content-box p, .content-box li {
    margin-left: 0; /* Align text and list items perfectly */
    margin-bottom: 15px;
    font-size: 16px; /* Consistent text size */
    color: #444;
}

.content-box ul, .content-box ol {
    margin-left: 20px; /* Indentation for lists */
    margin-bottom: 20px;
}

.content-box ul li, .content-box ol li {
    margin-bottom: 10px;
    line-height: 1.6; /* Better spacing between list items */
}

.content-box ol {
    list-style-position: inside; /* Align numbers neatly inside the list */
}

.content-box ul {
    list-style-type: disc; /* Use clean bullets for unordered lists */
    list-style-position: inside; /* Align bullets neatly inside the list */
}

.content-box blockquote {
    margin: 20px 0;
    padding: 15px 20px;
    background-color: #f1f1f1; /* Light gray background for emphasis */
    border-left: 4px solid #007bff; /* Highlighted border for quotes */
    font-style: italic;
    color: #555;
}

.action-buttons-row {
    display: flex;
    justify-content: space-between;
    margin-top: 20px; /* Space above the buttons */
    gap: 15px; /* Space between buttons */
}

.secondary-action-button {
    flex: 1; /* Equal width buttons */
    padding: 12px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    color: #333;
    cursor: pointer;
    transition: all 0.3s ease;
}

.secondary-action-button:hover {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
}

.secondary-action-button:disabled {
    background-color: #e6e6e6;
    color: #999;
    border-color: #ccc;
    cursor: not-allowed;
}

.report-header {
    position: relative; /* Allow positioning of the button */
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align title to the left */
    gap: 10px;
    margin-bottom: 20px;
}

.title {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    color: #333;
}

.copy-button {
    position: absolute;
    top: 0;
    right: 0; /* Align button to the top-right */
    padding: 10px 15px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.copy-button:hover {
    background-color: #0056b3;
}

.copy-success {
    font-size: 14px;
    color: #28a745; /* Green success color */
    font-weight: bold;
    margin-left: 10px; /* Add spacing from the button */
}

.metadata {
    font-size: 14px;
    color: #555;
    display: flex;
    flex-wrap: wrap;
    gap: 15px; /* Space between metadata items */
    margin-top: 20px;
}

