/* contactUs.css */
.contact-us-modal {
    padding: 20px;
    text-align: center;
  }
  
  .contact-textarea {
    width: 100%;
    height: 100px;
    margin-top: 10px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-family: 'Work Sans', sans-serif;
    font-size: 1rem;
  }
  
  .send-button {
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #0205D3;
    text-decoration: none;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Work Sans', sans-serif;
  }
  
  .send-button:hover {
    background-color: #0041cc;
  }
  