/* Updates Manager CSS */
.projects-manager {
    max-width: 800px;
    margin: 20px auto;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.header h1 {
    font-size: 24px;
    font-weight: 600;
    color: #333;
}

.new-project-button {
    background-color: #0041cc;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.new-project-button:hover {
    background-color: #002f99;
}

.tabs {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    border-bottom: 2px solid #e5e5e5;
}

.tab {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 500;
    color: #666;
    background: none;
    border: none;
    cursor: pointer;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    text-decoration: none;
    font-weight: 100;
}

.tab:hover {
    color: #212121;
}

.tab.active {
    color: #212121;
    border-bottom: 1px solid #212121;
    font-weight: 400;
}

.projects-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
}

.project-card {
    background-color: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative; /* Allows absolute positioning of elements inside */
    transition: box-shadow 0.3s ease;
    height: 100%; /* Ensure cards have consistent height */
}

.project-card:hover {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

.project-card h3 {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin-bottom: 10px;
}

.project-card p {
    font-size: 14px;
    color: #666;
    margin-bottom: 15px;
    text-align: left;
}

.status-badge {
    font-size: 12px;
    color: #fff;
    background-color: #28a745; /* Green for active */
    padding: 5px 10px;
    border-radius: 8px;
    font-weight: 500;
    display: flex; /* Align within the stats flexbox */
    align-items: center;
    justify-content: center;
    height: auto; /* Ensure it adapts to content */
}

.progress-bar-container {
    background-color: #e5e5e5;
    border-radius: 8px;
    height: 8px;
    margin: 10px 0;
    overflow: hidden;
    position: relative;
}

.progress-bar {
    background-color: #28a745; /* Green for progress */
    height: 100%;
    border-radius: 8px;
    transition: width 0.3s ease;
}

.progress-text {
    font-size: 12px;
    color: #666;
    margin-top: 5px;
    text-align: right;
}

.stats {
    display: flex;
    align-items: center;
    gap: 15px; /* Space between each stat-item */
    margin-top: auto; /* Push the stats section to the bottom of the card */
    padding-top: 10px; /* Add some padding for separation */
    border-top: 1px solid #e5e5e5; /* Subtle divider above the stats section */
    justify-content: flex-start; /* Left-align all items */
    flex-wrap: wrap; /* Allow wrapping if necessary */
    color: #666;
}

.stat-item {
    position: relative; /* Ensure positioning is relative to this element */
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    color: #666;
}

.icon {
    width: 25px; /* Set icon size */
    height: 25px;
    object-fit: contain;
    fill: #fa4949; /* Use this if the SVG supports 'fill' */
    color: #f35555; /* Use this for 'currentColor'-based SVGs */
    transition: fill 0.3s ease, color 0.3s ease; /* Smooth transition */
}

.stat-item[data-tooltip]:hover::after {
    content: attr(data-tooltip); /* Use the data-tooltip value as content */
    position: absolute;
    top: -30px; /* Position the tooltip above the stat-item */
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s ease-in-out;
}

.stat-item[data-tooltip]::after {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out;
}

.project-link {
    text-decoration: none;
}