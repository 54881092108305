.updates-tab {
    width: 100%;
    color: #333;
}

.updates-header {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    font-weight: bold;
    font-size: 14px;
    color: #888;
    border-bottom: 1px solid #ddd;
}

.updates-title {
    flex: 1;
    text-align: left;
}

.updates-created {
    flex: 0.2;
    text-align: right;
}

.updates-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.update-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    transition: background-color 0.3s;
}

.update-item:hover {
    background-color: #f9f9f9;
}

.update-details {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
}

.update-title {
    flex: 1;
    text-align: left;
    font-size: 16px;
    color: #333;
}

.update-created {
    flex: 0.2;
    text-align: right;
    font-size: 14px;
    color: #888;
}

.update-arrow {
    font-size: 16px;
    color: #aaa;
}
