.summary-tab {
    width: 100%;
    margin-top: 20px;
}

.summary-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    color: #555;
}

.summary-table th,
.summary-table td {
    text-align: left;
    padding: 10px;
}

.summary-table th {
    font-weight: 600;
    font-size: 12px;
    color: #a3a3a3;
}

.summary-type {
    font-weight: 500;
    color: #333;
    white-space: nowrap; /* Prevent text wrapping */
}

.summary-excerpt {
    font-size: 14px;
    color: #666;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; /* Prevent text wrapping */
}

.summary-date {
    font-size: 12px;
    color: #888;
    white-space: nowrap;
}

.summary-arrow {
    text-align: center;
}

.expand-arrow {
    font-size: 16px;
    cursor: pointer;
    color: #9f9f9f;
    text-align: center;
    transition: color 0.3s ease;
}

.expand-arrow:hover {
    color: #0041cc;
}
