/* Updates Manager CSS */
.updates-manager {
    max-width: 800px;
    margin: 20px auto;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.header h1 {
    font-size: 24px;
    font-weight: 600;
    color: #333;
}

.new-update-button {
    color: #999;
    border: 2px solid #c7c7c7;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.new-update-button:hover {
    background-color: #0042dc;
    color: white;
    border: 2px solid white;
}

.tabs {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    border-bottom: 2px solid #e5e5e5;
}

.tab {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 500;
    color: #666;
    background: none;
    border: none;
    cursor: pointer;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    text-decoration: none;
    font-weight: 100;
}

.tab:hover {
    color: #212121;
}

.tab.active {
    color: #212121;
    border-bottom: 1px solid #212121;
    font-weight: 400;
}

.updates-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.updates-table thead {
    background-color: none;
}

.updates-table th,
.updates-table td {
    text-align: left;
    padding: 10px;
    font-size: 14px;
    color: #555;
}

.updates-table th {
    font-weight: 500;
    font-size: 12px;
    color: #a3a3a3;
}

.updates-table td:first-child {
    font-weight: 500;
    color: #333;
}

.updates-table td input[type="checkbox"] {
    cursor: pointer;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination-button {
    padding: 5px 10px;
    margin: 0 5px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    background-color: #fff;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.pagination-button:hover {
    background-color: #0041cc;
    color: white;
}

.pagination-button.active {
    background-color: #0041cc;
    color: white;
}

/* Action Bar */
.action-bar {
    display: flex;
    align-items: center;
    margin-top: 20px;
    gap: 10px;
}

.project-dropdown {
    flex: 1;
    padding: 10px;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    font-size: 14px;
    color: #555;
    background-color: #fff;
    transition: border-color 0.3s ease;
}

.project-dropdown:focus {
    outline: none;
    border-color: #0041cc;
    box-shadow: 0 0 3px rgba(0, 65, 204, 0.3);
}

.link-button {
    padding: 10px 20px;
    background-color: #0041cc;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.link-button:hover {
    background-color: #002f99;
}

/* Expand Arrow */
.expand-arrow {
    font-size: 16px;
    cursor: pointer;
    color: #9f9f9f;
    text-align: center;
    transition: color 0.3s ease;
}

.expand-arrow:hover {
    color: #0041cc;
}
