.new-update-button {
  background-color: #f9f9f9; /* Light grey background */
  color: #333; /* Dark grey text */
  font-size: 14px;
  font-weight: bold;
  padding: 8px 16px;
  border: 1px solid #ccc; /* Darker grey border */
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.new-update-button:hover {
  background-color: #007bff; /* Blue background on hover */
  color: white; /* White text on hover */
  border-color: #007bff; /* Blue border on hover */
}

.new-update-button:disabled {
  background-color: #e6e6e6; /* Light grey background for disabled state */
  color: #999; /* Grey text for disabled state */
  border-color: #ccc; /* Grey border for disabled state */
  cursor: not-allowed;
}

/* General container styling */
.new-update-container {
    padding: 20px;
    font-family: "Inter", sans-serif;
  }
  
  /* Header styling */
  .new-update-header {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
  }
  
  /* Pills container */
  .pills-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  /* Individual pill */
  .pill {
    padding: 10px 20px;
    border: 1px solid #666;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 300; /* Light weight for unselected */
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    background-color: transparent;
    color: #666;
  }
  
  .pill.selected {
    background-color: #e6f4ff; /* Light blue background for selected pill */
    border-color: #007bff; /* Blue border for selected pill */
    color: #007bff; /* Blue text for selected pill */
    font-weight: bold; /* Bold font for selected */
  }
  
  /* Textarea styling */
  .new-update-textarea {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: none;
    resize: vertical;
    transition: border-color 0.2s;
    resize: none;
  }
  
  .new-update-textarea:focus {
    border-color: #007bff;
  }
  
  /* Submit button styling */
  .new-update-submit-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    transition: background-color 0.3s;
  }
  
  .new-update-submit-button:hover {
    background-color: #0056b3;
  }
  
  /* Dropdown container styling */
.goal-dropdown-container {
    margin-bottom: 20px;
  }
  
  /* Dropdown label styling */
  .goal-dropdown-label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 300;
    color: #999;
  }
  
  /* Dropdown styling */
  .goal-dropdown {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.2s;
  }
  
  .goal-dropdown:focus {
    border-color: #007bff;
  }
  .hidden {
    display: none;
  }

  /* Assistant Message Container Styling */
.assistant-message-container {
  background-color: #f9f9f9; /* Light gray background for contrast */
  border: 1px solid #ccc; /* Subtle border */
  border-radius: 8px; /* Rounded corners */
  margin-top: 20px; /* Space above the container */
  padding: 20px;
  text-align: left !important;
  }

/* Assistant Message Label */
.assistant-message-container .goal-dropdown-label {
  font-size: 14px;
  font-weight: 500;
  color: #666;
  margin-bottom: 10px; /* Space below the label */
}


.additional-actions-container {
    margin-top: 20px; /* Space above the additional actions */
    text-align: left; /* Align text and buttons to the left */
    width: 100%; /* Ensure it stretches full width */
  }
  
.additional-actions-heading {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px; /* Space below the heading */
    color: #666; /* Subtle gray color for secondary emphasis */
  }
  
.action-buttons-container {
    display: flex;
    flex-direction: column; /* Stack buttons vertically */
    gap: 10px; /* Add spacing between buttons */
    width: 100%;
  }
  
.secondary-action-button {
    width: 100%; /* Stretch full across a single line */
    padding: 10px 0; /* Vertical padding for a slim button style */
    font-size: 14px; /* Slightly smaller font for secondary actions */
    font-weight: 500; /* Medium weight for emphasis */
    border: 1px solid #ccc; /* Subtle gray border */
    border-radius: 5px; /* Rounded corners */
    background-color: #f9f9f9; /* Light gray background */
    color: #333; /* Darker text for readability */
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
.secondary-action-button:hover {
    background-color: #e6e6e6; /* Slightly darker gray on hover */
    border-color: #bbb; /* Slightly darker border on hover */
  }
    
/* Header Styling for Consistency */
.additional-actions-heading,
.goal-dropdown-label {
  font-size: 14px; /* Match the smaller, bold text */
  font-weight: 300; /* Make it slightly bold */
  color: #666; /* Use the gray text color for subtle emphasis */
  text-transform: uppercase; /* Match the style of "Update" and "Related Project" */
  margin-bottom: 10px; /* Space below the header */
  letter-spacing: 0.5px; /* Add slight spacing for a polished look */
}

/* Adjust the container spacing for alignment */
.additional-actions-container {
  margin-top: 20px; /* Space above the additional actions */
  text-align: left; /* Align text and buttons to the left */
  width: 100%; /* Stretch the container to full width */
}

.markdown-preview {
    width: 100%; /* Set width to 70% of the available viewport */
    margin: 10px;
    padding: 20px;
    border-radius: 10px;
    overflow-y: auto; /* Enable vertical scrolling for content overflow */
    max-height: 20vh; /* Limit height to 80% of the viewport */
    margin: 0 auto; /* Center horizontally */
    overflow-y: auto; /* Enable vertical scrolling */
    word-wrap: break-word; /* Ensure long words or URLs break correctly */
    text-align: left;
    font-size: 14px;
  }
  
.new-update-textarea {
    width: 100%;
    margin-top: 10px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: vertical;
  }
  
.secondary-action-button {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: transparent;
    color: #007bff;
    cursor: pointer;
  }
  
.secondary-action-button:hover {
    background-color: #f0f0f0;
  }

.new-update-textarea {
    width: 100%; /* Keeps it responsive */
    height: 100px; /* Adjust the height for a smaller box */
    padding: 10px;
    font-size: 14px; /* Adjust the font size for a smaller look */
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: none; /* Prevents resizing */
    overflow: auto; /* Ensures scrollbars appear if content overflows */
  }
  
.new-update-textarea:focus {
    border-color: #007bff; /* Optional: Highlight border on focus */
    outline: none; /* Removes the default focus outline */
  }

.primary-action-button {
    width: 100%; /* Full width */
    padding: 12px; /* Button padding */
    margin-top: 20px; /* Space above the button */
    font-size: 16px; /* Font size for readability */
    font-weight: bold; /* Bold text */
    color: #fff; /* White text */
    background-color: #007bff; /* Primary blue color */
    border: none; /* Remove border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s;
  }
  
.primary-action-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
.primary-action-button:disabled {
    background-color: #cccccc; /* Gray for disabled state */
    cursor: not-allowed; /* Disable pointer cursor */
  }