@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

.App-header,
.App,
.navbar,
.container,
header,
nav {
  box-shadow: none !important;
  border: none !important;
  background-color: transparent;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: #282828;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: inherit;
}

.sidebar {
  width: 250px;
  position: fixed; /* Keep the sidebar fixed */
  height: 100vh; /* Allow the sidebar to take up the full height of the viewport */
  overflow-y: auto; /* Add vertical scrolling */
  transition: transform 0.3s ease, width 0.3s ease;
}

.sidebar.closed {
  transform: translateX(-250px); /* Sidebar slides out of view */
}

.content {
  flex-grow: 1;
  margin-left: 250px; /* Leave space for the sidebar */
  transition: margin-left 0.3s ease;
  height: 100%;
}

.content.collapsed {
  margin-left: 0; /* Adjust when the sidebar is collapsed */
}

/* Global styles */
.app-container {
  display: flex;
  height: 100vh;
}

/* Sidebar handling */
.sidebar {
  width: 250px;
  transition: transform 0.3s ease, width 0.3s ease;
}

.sidebar.closed {
  transform: translateX(-250px); /* Sidebar slides out of view */
}

/* Content handling */
.content {
  flex-grow: 1;
  transition: margin-left 0.3s ease;
}

.content.collapsed {
  margin-left: 0; /* Adjust to remove sidebar space */
}