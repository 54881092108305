.new-summary-button {
    background-color: #f9f9f9; /* Light grey background */
    color: #333; /* Dark grey text */
    font-size: 14px;
    font-weight: bold;
    padding: 8px 16px;
    border: 1px solid #ccc; /* Darker grey border */
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .new-summary-button:hover {
    background-color: #007bff; /* Blue background on hover */
    color: white; /* White text on hover */
    border-color: #007bff; /* Blue border on hover */
  }
  
  .new-summary-button:disabled {
    background-color: #e6e6e6; /* Light grey background for disabled state */
    color: #999; /* Grey text for disabled state */
    border-color: #ccc; /* Grey border for disabled state */
    cursor: not-allowed;
  }


/* General container styling */
.new-summary-container {
    padding: 20px;
    font-family: "Inter", sans-serif;
}

/* Header styling */
.new-summary-header {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
}

/* Section headings (e.g., "Summary Scope", "Summary Type") */
.section-header {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400; /* Lighter font weight */
    color: #666;
    text-transform: uppercase;
}

/* Pills container */
.pills-container {
    display: flex;
    gap: 15px; /* Spacing between pills */
    margin-bottom: 20px;
    flex-wrap: wrap; /* Ensures pills wrap neatly on smaller screens */
    justify-content: flex-start; /* Left-aligns the pills */
}

.pill {
    padding: 8px 16px; /* Adjusted for a more compact look */
    border: 1px solid #666;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: transparent;
    color: #666;
    white-space: nowrap; /* Ensures pills remain in a single line */
}

.pill.selected {
    background-color: #e6f4ff;
    border-color: #007bff;
    color: #007bff;
    font-weight: 500;
}

/* Date inputs */
.date-inputs {
    display: flex;
    gap: 20px;
    justify-content: flex-start; /* Left-align the date inputs */
    margin-top: 10px;
    margin-bottom: 20px; /* Spacing after date inputs */
}

.date-input {
    flex: 1;
    max-width: 200px; /* Limits the width of each date input */
}

.date-input label {
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: #666;
    margin-bottom: 5px;
    text-align: left;
}

.date-input input {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.2s;
}

.date-input input:focus {
    border-color: #007bff;
}

/* Submit button styling */
.new-summary-submit-button {
    background-color: #007bff;
    color: #fff;
    padding: 12px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
    margin-top: 20px;
}

.new-summary-submit-button:hover {
    background-color: #0056b3;
}

.new-summary-submit-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}


/* Responsive styling */
@media (max-width: 768px) {
    .date-inputs {
        flex-direction: column;
        gap: 15px;
        align-items: flex-start; /* Left-align on smaller screens */
    }

    .pills-container {
        gap: 10px;
    }

    .date-input {
        max-width: 100%; /* Makes the date input full-width on small screens */
    }
}
