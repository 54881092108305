.page {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    background-color: #f7f9fc;
    min-height: 100vh;
}

.page-content {
    vertical-align: top;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.breadcrumb {
    align-self: flex-start;
    font-size: 14px;
    color: #666;
}

.breadcrumb a {
    text-decoration: none;
    color: #007bff;
}

.project-header {
    max-width: 800px;
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
}

.project-header h1 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #333;
}

.project-header .metadata {
    font-size: 14px;
    color: #555;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 15px; /* Space between metadata items */
}

.metadata p {
    margin: 0;
    font-size: 14px;
    color: #555;
}

.content-box {
    background-color: #fff;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.message {
    margin-top: 20px;
}

.message h3 {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
}

.message-content {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 8px;
    overflow-x: auto;
    white-space: pre-wrap; /* Preserve line breaks */
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    color: #333;
}

.action-buttons-row {
    display: flex;
    justify-content: space-between;
    margin-top: 10px; /* Space above the buttons */
    margin-bottom: 20px; /* Space below the buttons */
    gap: 10px; /* Space between buttons */
}

.secondary-action-button {
    flex: 1; /* Make all buttons equal width */
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    color: #333;
    cursor: pointer;
    transition: all 0.3s ease;
}

.secondary-action-button:hover {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
}

.secondary-action-button:disabled {
    background-color: #e6e6e6;
    color: #999;
    border-color: #ccc;
    cursor: not-allowed;
}

.editable-textarea-large {
    width: 100%; /* Full width of the container */
    height: 300px; /* Set a larger height for better usability */
    padding: 15px; /* Add inner padding for comfort */
    font-size: 16px; /* Keep font size readable */
    border: 1px solid #ccc;
    border-radius: 8px;
    resize: vertical; /* Allow vertical resizing only */
    overflow-y: auto; /* Add scrollbar for overflowing content */
    transition: border-color 0.3s ease;
}

.editable-textarea-large:focus {
    border-color: #007bff; /* Highlight border on focus */
    outline: none; /* Remove default focus outline */
}

.header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.settings-icon {
    cursor: pointer;
    font-size: 1.5rem;
}

/* Modal styling */
/* Modal styling */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure modal is above other elements */
}

.modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative; /* Contain child elements within modal */
    z-index: 1100;
    display: flex;
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center align the content */
    gap: 10px; /* Add space between elements */
}

.modal h2 {
    margin: 0; /* Remove default margin to align with buttons */
    font-size: 1.5rem;
    font-weight: bold;
}

/* Buttons */
.delete-button,
.close-button {
    display: block;
    margin: 0;
    padding: 10px;
    width: 90%;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
}

.delete-button {
    background: red;
    color: white;
}

.close-button {
    background: grey;
    color: white;
}
