.onboarding-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Work Sans', sans-serif;
  background-color: #f9f9f9;
  min-height: 100vh;
  padding: 20px; /* Add padding for spacing */
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  width: 100%;
}

.navbar {
  width: 100%;
  height: 40px;
  padding: 10px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h1 {
  color: #192752;
  font-size: 24px;
  padding-top: 20px;
}

p {
  color: #000000;
  font-size: 18px;
  margin-bottom: 20px;
}

.add-goal, .next-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #0205D3;
  text-decoration: none;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.add-goal {
  background-color: transparent;
  color: #0205D3;
  text-decoration: underline;
  font-size: 14px;
}

.add-goal:hover {
  color: #0041cc;
}

.next-button:hover {
  background-color: #0041cc;
  color: white;
}

.message {
  margin: 20px 0;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
}

.message.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.message.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.goal-card-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align content to the left */
  justify-content: flex-start;
  background-color: #ffffff;
  width: 100%;
  max-width: 600px; /* Set the same max width as the goal cards */
}

.goal-card {
  width: 100%
}

.checkbox-container {
  margin-top: 10px;
  margin-bottom: 10px;
}

.button-container {
  text-align: center; /* Align button to the right */
}

.modal-header {
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem; /* Adjust font size as needed */
  margin-top: 0;
  margin-bottom: 10px;
}

