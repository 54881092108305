.progress-summary-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: 'Work Sans', sans-serif;
}

.progress-summary-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
}

.progress-summary-header h1 {
  font-size: 1.8rem;
  color: #333;
  font-weight: bold;
  margin: 0;
}

.edit-button {
  background-color: #0205D3;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  font-family: 'Work Sans', sans-serif;
  margin-left: 10px;
  text-decoration: none;
}

.edit-button:hover {
  background-color: #0041cc;
}

.progress-summary-content {
  background-color: #ffffff;
  align-items: left;
  text-align: left;
  width: 100%;
  max-width: 600px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.markdown-viewer {
  background-color: #f9f9f9;
  padding: 15px 20px;
  border-radius: 8px;
  margin-top: 20px;
  line-height: 1.6;
  font-size: 1rem;
  color: #333;
  text-align: left; /* Ensures text is left-aligned */
  align-items: left;
}

.markdown-viewer h1, .markdown-viewer h2, .markdown-viewer h3, .markdown-viewer h4, .markdown-viewer h5, .markdown-viewer h6{
  margin-top: 1.5em;
  font-weight: bold;
  text-align: left;
  align-items: left;
}

.markdown-viewer ul, .markdown-viewer ol {
  padding-left: 20px;
  text-align: left;
  align-items: left;
}

.markdown-viewer li {
  padding-left: 20px;
  text-align: left;
  align-items: left;
}

.markdown-viewer p {
  padding-left: 20px;
  text-align: left;
  align-items: left;
}

.markdown-viewer blockquote {
  border-left: 4px solid #ddd;
  padding-left: 10px;
  color: #555;
  text-align: left;
  align-items: left;
}

.markdown-viewer code {
  background-color: #f4f4f4;
  padding: 2px 4px;
  border-radius: 3px;
  font-family: monospace;
  text-align: left;
  align-items: left;
}

.loading-spinner {
  font-size: 1.5rem;
  text-align: center;
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.dot {
  display: inline-block;
  font-size: 1.5rem;
  animation: bounce 0.6s infinite alternate;
}

.dot1 {
  animation-delay: 0s;
}

.dot2 {
  animation-delay: 0.2s;
}

.dot3 {
  animation-delay: 0.4s;
}

@keyframes bounce {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-8px);
  }
}

